// Create, Set and Get at_uniqID.
// Get and Set at_orgID.
// Create cookies for all orgActivity.
// Set cookies for all orgActivity.

$(document).ready(function () {

  //////////
  //////////
  // Create, Set and Get uniqID

  // Check to see if visitor has been to page and use existing uniqID.
  if (document.cookie.indexOf("at_uniqID") >= 0) {
    
    // Get the cookie.
    var at_uniqID = Cookies.get('at_uniqID');
    // alert('at_uniqID = ' + at_uniqID);
    
    // Set cookie with existing uniqID and update expires.
    Cookies.set('at_uniqID', at_uniqID, {expires:730});

  }
  else {
    
    // Create the at_uniqID from date.
    var today = new Date();
    var yyyy = today.getFullYear();
    var mm = today.getMonth()+1; //January is 0!
    var dd = today.getDate();
    var milli = today.getTime();
    // Place a 0 in front of the single digit days.
    if(dd<10) {
        dd='0'+dd
    } 
    // Place a 0 in front of the single digit months.
    if(mm<10) {
        mm='0'+mm
    }
    // Create at_uniqID var as year-month--day-milliseconds.
    // Sample: 2015-06-05-1433127321006
    var at_uniqID = yyyy+'-'+mm+'-'+dd+'-'+milli;
    // Create an uniqID cookie.
    Cookies.set('at_uniqID', at_uniqID, {expires:730});
    
  };
  
  
  //////////
  //////////
  // Get and Set orgid for logging and cookie setting.

  // 1. If orgID is set in the at_orgID cookie, set orgID var.
  if (document.cookie.indexOf("at_orgID") >= 0) {
    
    // Get the cookie.
    var orgid = Cookies.get('at_orgID');
    // Reset cookie expires.
    Cookies.set('at_orgID', orgid, {expires:730});
    // alert('at_orgID = ' + orgid);

  };
  
  // 2. If the page is setting an orgID (json pull, etc.), reset orgID var.
  if (page_orgid > 0) {
    
    // Set the orgid
    var orgid = page_orgid;
    // Reset cookie expires.
    Cookies.set('at_orgID', orgid, {expires:730});
    
  };
  
  // 3. If the user clicks on and orgActivity with orgid, reset orgID var.
  // This will take place within each orgAct click activity.
  
  
  //////////
  //////////
  // Log Org Activity.
  // Set orgAct cookies where necessary.
  //////////
  // tblOrgActivity Fields Key
  // uniqid :: 
  // orgid :: 
  // touchpoint :: TouchPoint Name (no spaces, beware of special characters).
  // method = email, inperson, online, phone, postal, wom.
  // location :: 
  // pageid :: if pageid is set, location will be set to page url in share.
  // action :: clicked, left message, replied, submitted, success, talked, viewed, sent.
  // cta :: 
  //////////

  // Set needed variables for submit string.
  // baseURL is set at the page level in '_layouts/app.blade.php'.
  // orgid and page id vars set on listing page.
  // alert(pageid);  
  
  // View - Signup Selection Page
  if(document.URL.indexOf("signup/index.php") >= 0) {
    // alert('Hi BJ');
    // alert('orgAct_SignupSelection_View'+' orgid=> '+orgid+' pageid=> '+pageid);
    Cookies.set('orgAct_SignupSelect_View', '1', {expires:730});
    $.getJSON(baseURL+"/signup/activity?orgid="+orgid+"&touchpoint=SignupSelect&method=Online&pageid="+pageid+"&action=View&uniqid="+at_uniqID);
  };
  
  // View - Signup Form Page
  if(document.URL.indexOf("signup/form.php") >= 0) {
    // alert('orgAct_SignupForm_View'+' orgid=> '+orgid+' pageid=> '+pageid);
    Cookies.set('orgAct_SignupForm_View', '1', {expires:730});
    $.getJSON(baseURL+"/signup/activity?orgid="+orgid+"&touchpoint=SignupForm&method=Online&pageid="+pageid+"&action=View&uniqid="+at_uniqID);
  };
  
  // Click - List Your Business
  $( "#orgAct-list" ).click(function() {
    // alert('orgAct_AddListing_Click'+' orgid=> '+orgid+' pageid=> '+pageid);
    Cookies.set('orgAct_AddListing_Click', '1', {expires:730});
    $.getJSON(baseURL+"/signup/activity?orgid="+orgid+"&touchpoint=AddListing&method=Online&pageid="+pageid+"&action=Click&cta=ListYourBusiness&uniqid="+at_uniqID);
  });
  
  // Click - Claim Your Listing
  $( 'a[id="orgAct-claim"]' ).click(function() {
    //// Acquire origid from onClick attribute and set orgid.  Need variable in templates.
    // var orgid = $(this).attr('data-orgid');
    //// Reset at_orgID cookie expires.
    // Cookies.set('at_orgID', orgid, {expires:730});
    // alert('orgAct_ClaimListing_Click'+' orgid=> '+orgid+' pageid=> '+pageid);
    Cookies.set('orgAct_ClaimListing_Click', '1', {expires:730});
    $.getJSON(baseURL+"/signup/activity?orgid="+orgid+"&touchpoint=ClaimListing&method=Online&pageid="+pageid+"&action=Click&cta=ClaimYourListing&uniqid="+at_uniqID);
  });
  
  // Click - Update Listing Info
  $( 'a[id="orgAct-update"]' ).click(function() {
    //// Acquireid orig from onClick attribute and set orgid.  Need variable in templates.
    // var orgid = $(this).attr('data-orgid');
    //// Reset at_orgID cookie expires.
    // Cookies.set('at_orgID', orgid, {expires:730});
    // alert('orgAct_UpdateListing_Click'+' orgid=> '+orgid+' pageid=> '+pageid);
    Cookies.set('orgAct_UpdateListing_Click', '1', {expires:730});
    $.getJSON(baseURL+"/signup/activity?orgid="+orgid+"&touchpoint=UpdateListingInfo&method=Online&pageid="+pageid+"&action=Click&cta=UpdateListingInfo&uniqid="+at_uniqID);
  });
  
  // Click - Signup Free Selection
  $( "#orgAct-signup-select-free" ).click(function() {
    // Set orgid - not really necessary since we already have it from the page.
    // alert('orgAct_SignupSelection_Free'+' orgid=> '+orgid+' pageid=> '+pageid);
    Cookies.set('orgAct_SignupSelection_Free', '1', {expires:730});
    $.getJSON(baseURL+"/signup/activity?orgid="+orgid+"&touchpoint=SignupSelect&method=Online&pageid="+pageid+"&action=Click&cta=Free&uniqid="+at_uniqID);
  });
  
  // Click - Signup Featured Selection
  $( "#orgAct-signup-select-featured" ).click(function() {
    // Set orgid - not really necessary since we already have it from the page.
    // alert('orgAct_SignupSelection_Featured'+' orgid=> '+orgid+' pageid=> '+pageid);
    Cookies.set('orgAct_SignupSelection_Featured', '1', {expires:730});
    $.getJSON(baseURL+"/signup/activity?orgid="+orgid+"&touchpoint=SignupSelect&method=Online&pageid="+pageid+"&action=Click&cta=Featured&uniqid="+at_uniqID);
  });

  // Click - Signup Form Submitted
  // orgActivity and cookie set in submitHandler on signupForm page.
  
     
});
