////////// 
/// Bootstrap Modals
/// Remove data on modal when it is hidden so that when we click on another instance
/// it will open the new data attached to the modal.
//////////

$(document).on("hidden.bs.modal", ".modal:not(.local-modal)", function (e) {
    $(e.target).removeData("bs.modal").find(".modal-content").empty();
});

////////// 
/// jQuery Click Tracking
//////////
function storeClick(contentid, pageid, target, ip, token) {
  // alert(contentid);
  console.log(contentid);
  var url = '/capture.php';

  $.ajax({
    url: url,
    method: 'POST',
    data: {
      '_token': token,
      'contentid':contentid,
      'pageid': pageid,
      'target': target,
      'ip' : ip
    },
    context: this,
    success: function (data) {
      // alert('Success!');
    },
    error: function (req, status, err) {
      console.log('Something went wrong', status, err);
    }
  });  

};


////////// 
/// jQuery Mask Plugin
/// https://igorescobar.github.io/jQuery-Mask-Plugin/
//////////

$(document).ready(function(){
  $('.phone').mask('(000) 000-0000');
});

////////// 
/// jQuery Scroll to Anchor
//////////

$(document).ready(function(){
    $( "a.scrollLink" ).click(function( event ) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top-300 }, 500);
    });
});

////////// 
/// Initialize Bootstrap Tool Tips
/// http://getbootstrap.com/javascript/#tooltips
//////////
///
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

////////// 
/// Lightbox for Bootstrap
/// https://github.com/ashleydw/lightbox
//////////
///
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});
