document.addEventListener('DOMContentLoaded', function() {
    // Header Space Fix
    if (window.innerWidth >= 992) {
        var ninecheaderElement = document.querySelector('.header-fixed .header-v8.header-sticky');

        if (ninecheaderElement) {
            var ninecheaderHeight = getComputedStyle(ninecheaderElement).height;
            var ninecbodyElement = document.querySelector('body.header-fixed-space-v2');

            if (ninecbodyElement) {
                ninecbodyElement.style.paddingTop = ninecheaderHeight;
            }
        }
    }

    // Select Menus
    if (typeof $.fn.selectmenu === 'function') {
        if ($("#banner_search_rv").length) {
            $("#banner_search_rv").selectmenu();
        }
        if ($("#banner_search_park").length) {
            $("#banner_search_park").selectmenu();
        }
        if ($("#banner_search_season").length) {
            $("#banner_search_season").selectmenu();
        }
        if ($("#banner_search_vacation_type").length) {
            $("#banner_search_vacation_type").selectmenu();
        }
        if ($("#banner_search_vacation_duration").length) {
            $("#banner_search_vacation_duration").selectmenu();
        }
        if ($("#banner_search_traveler_type").length) {
            $("#banner_search_traveler_type").selectmenu();
        }
    }

    // Popup Logic
    var popup = document.getElementById('popup');
    var closeBtn = document.getElementById('closePopup');
    var form = document.getElementById('contactForm');

    if (popup && closeBtn && form) {
        closeBtn.addEventListener('click', function() {
            popup.classList.remove('visible');
            popup.classList.add('visible-reverse');
            popup.classList.add('disable-popup');
        });
    
        form.addEventListener('submit', function(e) {
            e.preventDefault();
            popup.classList.remove('visible');
            popup.classList.add('visible-reverse');
            popup.classList.add('disable-popup');
        });
    
        window.addEventListener('scroll', function() {
            var scrollPosition = window.scrollY / (document.body.offsetHeight - window.innerHeight);
            if (scrollPosition > 0.7) {
                popup.classList.add('visible');
                popup.classList.remove('visible-reverse');
            } else {
                if (popup.classList.contains('visible')) {
                    popup.classList.remove('visible');
                    popup.classList.add('visible-reverse');
                }
            }
        });
    }
});